// Angular
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/core';
import '@angular/common';
import '@angular/forms';
import '@angular/http';

// RxJS
// tslint:disable-next-line:import-blacklist
import 'rxjs';
